.playback-speed-selector {
    display: flex;
    align-items: center;
    justify-content: center; /* Add this line */
    margin: 0 auto; /* Add this line to center the div horizontally */
  }
  
  .round-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
  }
  
  .speed-input {
    width: 50px;
    text-align: center;
    margin: 0 10px;
  }
  