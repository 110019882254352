#luckysheet_info_detail{
    display: none !important;
}
#luckysheet-input-box{
    color: black !important;
}

.luckysheet *{
    color: black !important;
}

